@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Poppins:wght@300;600&display=swap');
$primary-color: #29544c;
$secondary-color: #f4b658;
body {
  max-width: 100vw;
  position: relative;
  padding: 0 !important;
}
section {
  min-height: 60vh;
  background-color: white;
}
* {
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scrollbar-width: thin;
}
.text-primary-color {
  color: $primary-color;
}

.text-secondary-color {
  color: $secondary-color;
}

.font-size-reg {
  font-size: 1.2rem;
}
.font-weight-300 {
  font-weight: 300;
}

/// SCROLL BAR STYLE
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
*::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  border-radius: 0px;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1 {
  font-family: "Lobster", cursive;
  text-decoration: underline;
  font-size: 5em;
  margin: 1rem;
  @media screen and (max-width: 961px) {
    font-size: 2.5em;
  }
}
h2 {
  font-weight: 600;
  text-align: center;
  position: relative;
  margin-bottom: 35px;
  font-size: 40px;
  z-index: 2;
  &:after {
    content: "";
    width: 50px;
    height: 5px;
    background: #f4b658;
    position: absolute;
    top: 110%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}
h3 {
  margin-top: 50px;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
}
h4 {
  font-weight: 600;
  font-size: 26px;
  color: rgba(0, 0, 0, 0.856);
  margin-bottom: 2rem;
}
h5 {
  font-weight: 300;
  font-size: 21px;
  padding-bottom: 1rem;
}
p {
  color: rgba(0, 0, 0, 0.849);
  font-weight: 300;
  z-index: 2;
  position: relative;
  @media screen and (max-width: 961px) {
    font-size: 1rem;
  }
}

.description {
  padding: 0 10vw;
  text-align: center;
}
.slick-track * {
  &:focus {
    outline: 0;
  }
}
.slick-track {
  height: inherit;

  .slick-slide {
    
  

    
      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(5, 5, 5, 0.08);
        z-index:-2;

        @media screen and (max-width: 961px) {
          background: rgba(5, 5, 5, 0.1);
        }
      }
    .__hero_image{
    
      animation: zoom 5s infinite ease-in-out;
      
    }
  }
}
.btn {
  width: 160px;
  height: 50px;
  &:hover {
    cursor: pointer;
  }
}
.btn__disabled {
  @extend .btn;
  &:hover {
    cursor: unset;
  }
}
.btn__slick {
  margin-top: 2rem;
  border-radius: 1px;
  border-color: transparent;
  font-size: 1.3em;
  font-weight: 600;
  min-width: 200px;
  max-width: 600px;
  overflow-wrap: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 50px;
  background-color: white;
  @media screen and (max-width: 961px) {
    margin-top: 1rem;
    border-radius: 1px;
    border-color: transparent;
    font-size: 1em;
    font-weight: 300;
    width: 100px;
    height: 40px;
  }
}

/// Restaurant Styling
.restaurant__spotlight {
  background-color: $primary-color;
  position: relative;
  display: block;
  min-height: 110vh;
  padding-bottom: 20px;
  &::after {
    content: "";
    background-image: url("./assets/images/bg_small.jpg");
    background-position: center;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    mix-blend-mode: multiply;
    min-height: inherit;
    background-size: cover;
  }
}
.contact__information {
  background-color: $primary-color;
  position: relative;
  display: block;
  color: white;
  min-height: 75vh;
  a:visited{
    color:white;
  }
  h4 {
    color: white;
  }
  p {
    color: white;
  }
  &::after {
    content: "";
    background-image: url("./assets/images/contact_small.jpg");
    background-position: center;
    background-size: 100%;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    mix-blend-mode: multiply;
    min-height: inherit;
  }
  .MuiGrid-item {
    position: relative;
    z-index: 2;
    ul {
      li {
        list-style-type: none;
      }
    }
  }
}
.spotlight__item {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  min-height: 300px;
  @media screen and (max-width: 961px) {
    background-color: white;
  }
  span {
    font-size: 22px;
  }

  @media screen and (max-width: 961px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .react-parallax {
      margin-top: 1.5rem;
      min-width: 300px;
    }
  }
  @media screen and (min-width: 961px) {
    &:hover {
      cursor: pointer;
    }
  }
}
.spotlight__item__reversed {
  @extend .spotlight__item;
  flex-direction: row-reverse;
  @media screen and (max-width: 961px) {
    flex-direction: column;
  }
}
.submenu-parent{
  ul{
    list-style: none;
  }
}
.restaurant__cards {
  position: relative;
  .item {
    border-bottom: 1px dotted #d4d4d4;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 1020px) {
      flex-wrap: wrap;
      &:last-child {
        border-bottom: unset;
      }
      text-align: center;
    }
    @media screen and (min-width: 961px) {
      &:nth-last-child(2) {
        border-bottom: unset;
      }
      &:last-child {
        border-bottom: unset;
      }
    }
  }
}

.restaurant__card {
  width: 100%;
  border: 1px solid #dfdfdf;
  .item {
    width: 200px;
    min-height: 200px;
    min-width: 50%;
    .item__img {
      height: 150px;
      min-width: 150px;
      border-radius: 100%;

      div {
        height: 150px;
        width: 150px;
      }
    }

    .item__title_price {
      padding-right: 1rem;
      @media screen and (max-width: 1220px) {
        flex-direction: column;
        padding-right: unset;
      }
    }
    @media screen and (max-width: 1020px) {
      flex-wrap: wrap;
    }
  }
}

.contact__media_button {
  border-radius: 100%;
  width: 75px;
  height: 75px;
  background-color: white;
  margin: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $secondary-color;
    cursor: pointer;
  }
}

.btn__card {
  border: 0;
  background-color: black;
  color: white;
  border-left: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
  &:hover {
    background-color: #141414;
  }
  &.active_btn_card {
    background-color: #ffffff;
    color: black;
  }
}
.tour__frame {
  border: 0;
}

.mouse {
  max-width: 2vw;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 10vh;
  @media screen and (max-width: 1020px) {
    display: none;
  }
}
@keyframes scroll {
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(36px) scaleY(2);
    opacity: 0.01;
  }
}
.scroll {
  animation-name: scroll;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
  animation-iteration-count: infinite;
  transform-origin: 50% 20.5px;
  will-change: transform;
}
footer {
  background-color: $primary-color;
  color: white;
  min-height: 60vh;
  padding: 1rem;
  display: flex;
  align-items: center;
  h4 {
    color: white;
  }

  p {
    color: white;
  }
  ul {
    li {
      margin: 5px 0;
      list-style-type: none;
      font-size: 13px;
      a {
        color: white;
      }
    }
  }

  .credits {
    margin-top: 10rem;
  }
  @media screen and (max-width: 1020px) {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
    }
    ul {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    h4 {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0 !important;
    }
    .credits {
      margin-top: 2rem;
    }
  }
}

.logo {
  &:hover {
    cursor: pointer;
  }
}

/// BURGER MENU
.menu-wrap {
  background-color: #050505;
  width: 100vw;
  height: 85px;
  .logo_burger {
    position: absolute;
    z-index: 99;
    color: white;
    display: flex;
    flex-direction: column;
    left: 1em;
    // top: 1em;
    text-align: center;
  }
  nav {
    background-color: $primary-color;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 9999;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      transition: all 0.3s ease-in-out;
      width: 100vw;
      display: flex;
      flex-direction: column;
      margin-right: 25vw;
      align-items: center;
      margin: 40px 0;
      li {
        transform: translateY(50px);
        opacity: 0;

        a {
          display: block;
          text-decoration: none;
          font-size: 25px;
          text-align: right;
          padding: 10px 0;
          color: white;
          font-weight: 600;
          transition: all 0.2s ease-in-out;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .toggle-btn {
    display: block;
    position: absolute;
    z-index: 1999;
    right: 1em;
    top: 1em;
    cursor: pointer;
    z-index: 99999999999;
    .bar {
      width: 40px;
      height: 5px;
      margin: 7px auto;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.301);
    }
  }
  .btn-toggle-btn {
    display: block;
    position: fixed;
    z-index: 10;
    right: 1em;
    top: 1em;
    cursor: pointer;
    z-index: 99999999999;
    .bar {
      width: 30px;
      height: 5px;
      margin: 7px auto;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 0 3px 1px rgba(212, 212, 212, 0.055);
      &:nth-child(2) {
        transform: translateX(50px);
        opacity: 0;
      }
      &:nth-child(1) {
        transform: translateY(16px) rotate(45deg);
      }
      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }

  .active-nav {
    opacity: 1;
    visibility: visible;

    ul {
      top: 70px;

      li {
        transform: translateY(0px);
        opacity: 1;
        @for $i from 1 through 9 {
          $t: $i * 0.1;
          &:nth-child(#{$i}) {
            transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) #{$t}s;
          }
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    display: none;
  }
}
// CONTACT COMPONENT
._contact__openingshours {
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: 300;
    font-size: 1.2rem;
    .day {
      color: $secondary-color;
    }
    .hours_or_closed {
      color: white;
      margin-bottom: 10px;
    }
  }
}
.__contact_form_parent {
  box-shadow: unset;
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
}
._contact__form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 1.5rem;
  background-color: transparent;
  input {
    padding: 10px 0px 5px 5px;
    margin: 8px 0;
    outline: 0;
  }
  textarea {
    padding: 10px 0px 5px 5px;
    margin: 15px 0;
    height: 160%;
    outline: 0;
  }
  button {
    background: transparent;
    border-radius: 5%;
    border: white 1px solid;
    color: white;
    outline: 0;
    &:hover {
      background-color: rgba(255, 255, 255, 0.11);
    }
  }
}

// COMING SOON
.__coming_soon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  .__background_img {
    object-fit: cover;
  }
  ._boxed_item {
    z-index: 2;
    background-color: $primary-color;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 200px;
    align-items: center;
    padding: 1.2rem 0;
    position: absolute;
    .logo_img {
      width: 300px;
      height: 107px;
      &:hover {
        cursor: pointer;
      }
    }
    p {
      color: white;
      font-weight: 600;
    }
  }
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2222864145658263) 35%,
      rgba(0, 212, 255, 0) 100%
    );
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

._dialog {
  justify-content: space-between;

  .img-container {
    padding: 0px 25px;
    @media screen and (max-width: 920px) {
      padding: 25px 0;
    }
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 1.2rem;
    }
    h3 {
      font-size: 1.4rem;
      margin-top: 20px;
    }
  }
  .img-container {
    object-fit: cover;
    img {
      min-width: 560px;
      max-width: 100%;

      @media screen and (max-width: 1020px) {
        max-height: 500px;
        min-width: 100%;
      }
      @media screen and (max-width: 620px) {
        max-height: 250px;
        min-width: 100%;
      }
    }
  }
}

._burger__socials {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all 0.2s;
}

// ID MANIPULATION
.about {
  background-image: url("./assets/ui/branch.png");
  background-repeat: no-repeat;
  background-position-x: 130%;
  max-width: 100vw;
  min-height: 101vh;
  @media screen and (max-width: 1024px) {
    background-position-x: 0;
  }
  @media screen and (max-width: 1023px) {
    background-image: unset;
  }
}

#top {
  min-height: 80vh;
}

#tour {
  margin-bottom: -3px;
}

/// LOADER

.__loader_ui {
  position: absolute;
  z-index: 999999999999999999999999999999999;
  background-color: white;
  width: 100vw;
  height: 5000vh;
  display: flex;
  padding-top: 50vh;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  img {
    width: 80vw;
    z-index: 9999999;
  }
}
.MuiBreadcrumbs-li {
  padding-bottom: 2px;
  &:hover {
    cursor: pointer;
  }
}
.MuiCircularProgress-colorPrimary {
  color: $primary-color !important;
}

.MuiButton-textPrimary {
  color: $primary-color !important;
}

.__boxed_product {
  img {
    max-width: 450px;
    min-width: 100%;
    object-fit: cover;
  }
}

.__share_button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.closingButton {
  position: absolute !important;
  width: 33px;
  height: 33px;
}

header {
  .handshake {
    margin-top: 10%;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .top_bar {
    background-color: $primary-color;
    min-height: 35px;
    .MuiContainer-root {
      min-height: inherit;
      .MuiBox-root {
        min-height: inherit;
      }
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  .inner {
    .left {
      a {
        margin-right: 3rem;
      }
    }
    .right {
      a {
        margin-left: 1rem;
      }
    }
    ul {
      height: 100%;
      display: flex;
      align-items: center;
      li {
        height: 100%;
        a {
          display: flex;
          align-items: center;

          text-decoration: none;
          color: white;
          &:hover {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }

  .__nav_bar_ruler {
    height: 100px;
    display: flex;
    width: 100vw;
    justify-content: space-between;
    .__nav_bar {
      background-color: black;
      color: white;
      top: -100px;
      display: flex;
      justify-content: space-between;
      width: 100vw;
      z-index: 1;

      .__sub_menu {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -150px;
        top: 30px;
        display: flex;
        flex-direction: column;
        li {
          margin: 0px auto 0px auto;
          padding: 5px 20px;
          background-color: black;
          min-width: 200px;
          border-bottom: 1px solid #0f0f0f;
        }
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      ul {
        display: flex;
        li {
          list-style-type: none;
          color: white;
          margin-left: 12px;
          margin-right: 12px;
          display: flex;
          align-items: center;
          &:hover {
            cursor: pointer;
            color: white;
          }
          a {
            display: flex;
            align-items: center;
            color: white;
            text-decoration: none;
            font-weight: 300;
            &:hover {
              cursor: pointer;
              color: rgb(226, 226, 226);
            }
          }
        }
        > .active {
          > a {
            color: white;
            text-decoration: none;
            font-weight: 600;
          }
        }
      }
      .__divider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding-left: 16px;
        padding-right: 16px;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.301);
        @media screen and (min-width: 600px) {
          padding-left: 24px;
          padding-right: 24px;
        }
        @media screen and (min-width: 1280px) {
          max-width: 1280px;
        }
        svg {
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
      }

      transition: all 0.6s ease-in-out;
    }
    .__nav_bar_fixed {
      @extend .__nav_bar;
      background-color: black;
      top: 0;
      position: fixed;
      height: 80px;
      z-index: 99;
      display: flex;

      img {
        max-width: 120px;
      }
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .top_mast_slider {
    height: 75vh;
    h3 {
      font-weight: 300;
      padding: 0 5em;
      font-size: 1.5em;
      margin: 0;
      color: white;
      @media screen and (max-width: 992px) {
        font-size: 1em;
        padding: 0 1em;
      }
    }
    .__hero_image {
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      z-index: -3;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        position: absolute;
        z-index: -3;
        opacity: 0.9;
      }
    }
    .slick-slider {
      width: 100vw;
      height: 100%;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.301);
    }
    .slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      height: 75vh;
      h1 {
        color: white;
      }

      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.1);
        z-index: -2;

        @media screen and (max-width: 961px) {
          background: rgba(0, 0, 0, 0.1);
        }
      }
      @media screen and (max-width: 1234px) {
        h1 {
          margin-top: 2vh;
          font-size: 2em;
        }
      }
      .slick-list {
        width: 100vw;
        height: 100%;
        z-index: 1;
        @media screen and (max-width: 1234px) {
          width: unset;
        }
      }
      @media screen and (max-width: 1234px) {
        width: unset;
      }
    }

    .slick-dots {
      z-index: 1;
      bottom: 2vh;
      @media screen and (max-width: 1234px) {
      }
      li {
        width: 18px;
        height: 12px;
        margin-bottom: 10px;
      }

      li button:before {
        border: 1px solid black;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        font-family: unset;
        font-size: 0;
        background-color: black;
      }
    }
  }
}

.cookies-consent {
  position: fixed;
  bottom: 1em;
  left: 1em;
  background: rgba(0, 0, 0, 0.918);
  width: 400px;
  height: 150px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.301);
  @media screen and (max-width: 961px) {
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 150px;
  }
  .cookies-inner {
    display: flex;
    height: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      color: white;
      a {
        text-decoration: none;
        color: $primary-color;
      }
    }
  }
}

// COMING SOON
.__coming_soon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  .__background_img {
    object-fit: cover;
  }
  ._boxed_item {
    z-index: 2;
    background-color: $primary-color;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 200px;
    align-items: center;
    padding: 1.2rem 0;
    position: absolute;
    .logo_img {
      width: 300px;
      height: 107px;
      &:hover {
        cursor: pointer;
      }
    }
    p {
      color: white;
      font-weight: 600;
    }
  }
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2222864145658263) 35%,
      rgba(0, 212, 255, 0) 100%
    );
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

/// loader
.loading_screen {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}
.lds-spinner {
  margin-top: 2%;
  color: $primary-color;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: $primary-color;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.onHover {
  &:hover {
    cursor: pointer;
  }
}


.anchorHighlight {
  color: #f4b658 !important;
  font-weight: 800 !important;
}

.notification-box {
  position: relative;
  padding: 10px 20px;
  background-color: #f44336; 
  color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1000;
}
